<template>
  <div class="pageContainer">
    <loginDialog ref="dialog"/>
    <div class="header">
      <div v-if="$parent.status_name!=1" class="title">{{ $fanyi('商品展示') }}</div>
      <div class="goodsListHeaderOperationContainer">
        <div class="strSearchItemContainer" style="border-bottom: none">
          <div :class="bottomFiltrateIndex==0?'selectFiltrateContainer':'filtrateContainer'"
               @click="updateBottomFiltrateIndex(0)">総合ランキング
          </div>
          <div :class="bottomFiltrateIndex==1?'selectFiltrateContainer':'filtrateContainer'"
               @click="updateBottomFiltrateIndex(1)">
            <div>販売数</div>
            <img :src="monthlySalesImg" alt="" style="margin-top: 4px">
          </div>
          <div :class="bottomFiltrateIndex==2?'selectFiltrateContainer':'filtrateContainer'"
               @click="updateBottomFiltrateIndex(2)">
            <div>リピート率</div>
            <img :src="repurchaseRateImg" alt="" style="margin-top: 4px">
          </div>
          <div :class="bottomFiltrateIndex==3||bottomFiltrateIndex==4?'selectFiltrateContainer':'filtrateContainer'"
               @click="newUpdateBottomFiltrateIndex">
            <div>価格</div>
            <div class="iconContainer">
              <img :src="priceAscendingOrderImg" alt="" style="margin-bottom: 2px">
              <img :src="priceDescendingOrderImg" alt="">
            </div>
          </div>
        </div>
        <div class="btn" @click="delGoods">{{ $fanyi('移出货盘') }}</div>
      </div>
    </div>
    <div class="footer">
      <div v-if="goodsListIsNull && $parent.goodsList.length == 0" class="goodsListIsNull">
        <div class="message">
          {{ $fanyi('该货盘下没有商品，请换个货盘再试') }}
        </div>
      </div>
      <div v-show="!goodsListIsNull || $parent.goodsList.length > 0" v-loading="loading"
           style="width: 1400px;margin: 0 auto">
        <div class="SearchConterCrosswise">
          <div v-for="(item, index) in $parent.goodsList" :key="index"
               :data-aplus-report="item.traceInfo"
               :style="item.hoverStatus === true ? 'border: 1px solid #B4272B;' : ''"
               class="SearchConterCrosswiseCon" data-tracker="offer"
               @mouseleave="goodsMouseleave(index)">
            <!-- 放图片的div -->
            <div class="SearchConterCrosswiseImg" @click="toCommodityDetails(item)"
                 @mouseenter="goodsMouseenter(index)">
              <div class="inputContainer">
                <input v-model="item.checked" type="checkbox" @click.stop="$fun.removeEventBubbling()"/>
              </div>
              <el-image :src="item.pic" class="biggoodsImg">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <div slot="placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
              <!--              <div v-show="item.hoverStatus === true" class="findSimilarityContainer"-->
              <!--                   @click.stop="imgSearchGoods(item.imgUrl)">{{ $fanyi("查找相似") }}-->
              <!--              </div>-->
            </div>
            <!-- 显示其他信息的div -->
            <div v-show="item.hoverStatus === false"
                 class="rests">
              <p :title="item.goods_detail.titleT" class="goodsTitle">
                <span v-if="item.from_platform==1688" class="type">1688</span>
                {{ item.goods_detail.titleT }}
              </p>
              <p>
              <span>
                {{ Number(item.price).toFixed(2) }}{{ $fanyi("元") }}
                （{{ (Number(item.price) * exchangeRate).toFixed(0) }}{{ $fanyi("円") }}）
              </span>
                <span v-if="item.from_platform==1688" style="color: #999999">販売数<span
                    style="color: #B4272D!important;">{{
                    item.month_sold
                  }}</span>件</span>
              </p>
              <div style="display: flex;flex-wrap: wrap">
                <div v-if="item.from_platform==1688" style="display: flex;flex-wrap: wrap;margin-bottom: 6px">
                  <div v-for="(newItem,newIndex) in item.goods_detail.offerIdentities" :key="newIndex">
                    <div v-if="newItem=='super_factory'" :style="newIndex==0?'margin-right: 6px':''"
                         class="powerfulMerchantsContainer">
                      <img alt="" src="../../../assets/1688/powerfulMerchantsIcon.png">
                      <span>スーパーメーカー</span>
                    </div>
                    <div v-if="newItem=='powerful_merchants'" :style="newIndex==0?'margin-right: 6px':''"
                         class="superFactoryContainer">
                      <img alt="" src="../../../assets/1688/superFactoryIcon.png">
                      <span>実力商家</span>
                    </div>
                  </div>
                </div>
                <div v-if="item.from_platform==1688" class="repurchaseRate">リピート率
                  <span>{{ Number(item.repurchase_rate) }}</span>％
                </div>
              </div>
            </div>
            <div v-show="item.hoverStatus === true"
                 class="shopInfo">
              <div class="shopInfoHeader" style="justify-content: space-between">
                <div class="goodCompare" @click.stop="addCommercialMatch(item)">
                  <img alt="" src="../../../assets/1688/goodCompare.png">
                  比較
                </div>
                <div class="goodsFavorite" @click.stop="favoriteGoodsAdd(item)">
                  <img v-if="item.goodsFavoriteStatus" alt="" src="../../../assets/SearchResultsPage/Collected.png">
                  <img v-else alt="" src="../../../assets/SearchResultsPage/collection.png">
                  {{ item.goodsFavoriteStatus ? $fanyi("取消收藏") : $fanyi("收藏") }}
                </div>
              </div>
              <div v-if="item.from_platform==1688"
                   :style="item.shopInfo==undefined||$fun.isArray(item.shopInfo)||(!$fun.isArray(item.shopInfo)&&item.shopInfo.shopName==undefined&&item.shopInfo.year==undefined)?'border-bottom:none':''"
                   class="shopDataContainer">
                <div class="newRateContainer">
                  <div style="margin-right: 7px">総合評点</div>
                  <el-rate
                      v-model="item.goods_detail.tradeScore"
                      disabled
                      text-color="#ff9900">
                  </el-rate>
                </div>
                <div class="shopDataBottom">
                  <div class="repurchaseRate" style="margin-right:6px">リピート率
                    <span>{{ Number(item.repurchase_rate) }}</span>％
                  </div>
                  <div class="repurchaseRate" style="width: 128px;">販売数<span>{{
                      item.month_sold
                    }}</span>件
                  </div>
                </div>
              </div>
              <div
                  v-if="item.shopInfo!=undefined&&!$fun.isArray(item.shopInfo)&&item.from_platform==1688&&item.shopInfo.shopName!=undefined&&item.shopInfo.year!=undefined"
                  class="shopNameContainer">
                <div
                    v-if="item.goods_detail.offerIdentities!=undefined&&item.goods_detail.offerIdentities.indexOf('tp_member')==-1"
                    class="yearContainer">
                  <div><img alt="" src="../../../assets/1688/shopIcon.png"></div>
                  <div>{{ item.shopInfo.year }}年</div>
                </div>
                <div
                    v-if="item.goods_detail.offerIdentities!=undefined&&item.goods_detail.offerIdentities.indexOf('tp_member')!=-1"
                    class="tpMemberContainer">
                  <div><img alt="" src="../../../assets/1688/tp_member_icon.png"></div>
                  <div>{{ item.shopInfo.year }}年</div>
                </div>
                <div :title="item.shopInfo.shopName" class="shopName cursorPointer"
                     @click="shopPageJump(item)">{{
                    item.shopInfo.shopName
                  }}
                </div>
              </div>
            </div>
          </div>
          <div v-show="$parent.keywordLoading" class="keywordLoadingContainer">
            <div class="flexAndCenter">
              <img alt="" src="@/assets/loading.gif">
              <div>読み込み中</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginDialog from "@/components/public/loginDialog.vue";

export default {
  components: {loginDialog},
  data() {
    return {
      bottomFiltrateIndex: null,
      goodsListIsNull: false,
      loading: null,
      keywordLoading: false,
      monthlySalesImg: require('../../../assets/1688/bottomNoSelect.png'),
      repurchaseRateImg: require('../../../assets/1688/bottomNoSelect.png'),
      priceAscendingOrderImg: require('../../../assets/1688/topNoSelect.png'),
      priceDescendingOrderImg: require('../../../assets/1688/bottomNoSelect.png'),
    }
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  methods: {
    //筛选
    updateBottomFiltrateIndex(val) {
      this.bottomFiltrateIndex = val;
      switch (val) {
        case 0:
          this.monthlySalesImg = require('../../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomNoSelect.png');
          this.$parent.palletConditionFrom.sortPrice = '';
          this.$parent.palletConditionFrom.sortMonthSold = ''
          this.$parent.palletConditionFrom.sortRepurchaseRate = ''
          break;
        case 1:
          this.monthlySalesImg = require('../../../assets/1688/bottomSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomNoSelect.png');
          this.$parent.palletConditionFrom.sortPrice = '';
          this.$parent.palletConditionFrom.sortMonthSold = 'desc'
          this.$parent.palletConditionFrom.sortRepurchaseRate = ''
          break;
        case 2:
          this.monthlySalesImg = require('../../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomNoSelect.png');
          this.$parent.palletConditionFrom.sortPrice = '';
          this.$parent.palletConditionFrom.sortMonthSold = ''
          this.$parent.palletConditionFrom.sortRepurchaseRate = 'desc'
          break;
        case 3:
          this.monthlySalesImg = require('../../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomNoSelect.png');
          this.$parent.palletConditionFrom.sortPrice = 'asc';
          this.$parent.palletConditionFrom.sortMonthSold = ''
          this.$parent.palletConditionFrom.sortRepurchaseRate = ''
          break;
        case 4:
          this.monthlySalesImg = require('../../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomSelect.png');
          this.$parent.palletConditionFrom.sortPrice = 'desc';
          this.$parent.palletConditionFrom.sortMonthSold = ''
          this.$parent.palletConditionFrom.sortRepurchaseRate = ''
          break;
      }
      this.$parent.goodsList = [];
      this.goodsListIsNull = false;
      this.$parent.palletConditionFrom.page = 1;
      this.$parent.getGoodsList();
    },
    //筛选
    newUpdateBottomFiltrateIndex() {
      if (this.bottomFiltrateIndex != 3 && this.bottomFiltrateIndex != 4) {
        this.updateBottomFiltrateIndex(3);
      } else {
        if (this.bottomFiltrateIndex != 3) {
          this.updateBottomFiltrateIndex(3);
        } else {
          this.updateBottomFiltrateIndex(4);
        }
      }
    },
    //将商品移除货盘
    delGoods() {
      let goods_id = [];
      this.$parent.goodsList.forEach((goodsItem) => {
        if (goodsItem.checked) {
          goods_id.push(goodsItem.id);
        }
      })
      if (goods_id.length == 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'));
        return
      }
      this.$confirm(
          this.$fanyi('是否把选中商品移除？'),
          '注意',
          {
            confirmButtonText: this.$fanyi('确定'), //确定
            cancelButtonText: this.$fanyi('取消'), //取消
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        this.$api.productRemove({
          ids: goods_id
        })
        .then((res) => {
          if (res.code != 0) return this.$message.success(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg));
        });
      }).catch(() => {

      });
    },
    //商品详细鼠标移入
    goodsMouseenter(index) {
      this.$parent.goodsList[index].hoverStatus = true;
      this.$forceUpdate();
    },
    shopPageJump(item) {
      let super_factory_status = undefined;
      let powerful_merchants_status = undefined;
      if (item.sellerIdentities != undefined) {
        item.sellerIdentities.indexOf('super_factory') != -1 ? super_factory_status = true : undefined
        item.sellerIdentities.indexOf('powerful_merchants') != -1 ? powerful_merchants_status = true : undefined
      }
      if ((item.shopInfo.shopName != '' && item.shopInfo.shopName != undefined) && ((item.shopInfo.shop_id != '' && item.shopInfo.shop_id != undefined) || (item.shopInfo.shopId != '' && item.shopInfo.shopId != undefined)) && (item.shopInfo.wangwang != '' && item.shopInfo.wangwang != undefined)) {
        this.$fun.routerToPage(`/shopGoods?shopId=${item.shopInfo.shop_id == undefined ? item.shopInfo.shopId : item.shopInfo.shop_id}&wangName=${item.shopInfo.wangwang}&shopName=${item.shopInfo.shopName}&shopType=1688${super_factory_status == true ? '&super=true' : ''}${powerful_merchants_status == true ? '&powerful=true' : ''}`)
      }
    },
    // 添加收藏商品
    favoriteGoodsAdd(item) {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      let datas = {
        shop_type: item.from_platform,
        goods_id: item.goods_id,
        title: item.goods_detail.titleC,
        image_url: item.pic,
        price: item.price,
      };
      if (!item.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$message.success(this.$fanyi(res.msg))
          this.$forceUpdate();
        });
      } else {
        let arr = [];
        arr.push({
          shop_type: item.from_platform,
          goods_id: item.goods_id
        })
        this.$api.favoriteGoodsDelete({delete: arr}).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg))
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$forceUpdate();
        });
      }
    },
    //追加比较
    addCommercialMatch(val) {
      let commercialMatchList = []
      if (localStorage.getItem("commercialMatchList") != null) {
        commercialMatchList = JSON.parse(localStorage.getItem('commercialMatchList'));
      }
      if (commercialMatchList.length < 30) {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('意向售卖参考增加比较商品数量统计');
        }
        let flag = commercialMatchList.findIndex(item => item.title == val.goods_detail.titleT)
        if (flag != -1) {
          this.$message.error('該当商品は追加済みです、他の商品をお選び下さい。')
        } else {
          commercialMatchList.push({
            imgUrl: val.pic,
            title: val.goods_detail.titleT,
            titleC: val.goods_detail.titleC,
            price: val.price,
            shopType: val.shopType,
            goodsId: val.goodsId,
            monthSold: val.month_sold == undefined ? '' : Number(val.month_sold),
            repurchaseRate: val.repurchase_rate == undefined ? '' : Number(val.repurchase_rate),
            shopName: val.goods_detail.shopName,
            link: `https://www.rakumart.com/ProductDetails?goods_id=${val.goods_id}&fromPlatform=${val.from_platform}`
          })
          localStorage.setItem("commercialMatchList", JSON.stringify(commercialMatchList));
          this.$store.commit('setCommercialMatchGoodsCount', commercialMatchList.length)
          this.$message.success('操作が成功しました')
        }
      } else {
        this.$message.error('最大30個の商品を追加できます')
      }
    },
    //图片搜索
    imgSearchGoods(img) {
      this.$store.commit("getsearchImg", img);
      this.$fun.routerToPage("/CommoditySearch?type=imgSearch&imgUrl=" + img);
    },
    //跳转到商品详情
    toCommodityDetails(val) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('通过意向售卖参考的商品展示进入商品详情页统计');
      }
      this.$fun.toCommodityDetails(val.goods_id, val.from_platform)
    },
    //商品详细鼠标移出
    goodsMouseleave(index) {
      this.$parent.goodsList[index].hoverStatus = false;
      this.$forceUpdate();
    },
  }
}
</script>

<style lang="scss" scoped>
.pageContainer {
  width: 1400px;
  margin: 24px auto 0;

  .header {
    margin-bottom: 18px;
    border-radius: 6px 6px 0 0;
    background: #fff;

    .title {
      height: 70px;
      border-bottom: 1px solid #DFDFDF;
      line-height: 70px;
      padding-left: 30px;
      font-weight: bold;
      font-size: 20px;
      color: #333333;
    }

    .goodsListHeaderOperationContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 30px;

      .strSearchItemContainer {
        display: flex;
        align-items: center;
        padding-left: 30px;
        height: 52px;

        .filtrateContainer, .selectFiltrateContainer {
          display: flex;
          align-items: center;
          margin-right: 60px;
          cursor: pointer;
          font-size: 14px;

          div {
            margin-right: 5px;
          }

          .iconContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 2px;
          }
        }

        .selectFiltrateContainer {
          font-weight: bold;
          color: #B4272B;
        }
      }

      .btn {
        width: 80px;
        height: 36px;
        background: #B4272B;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }
    }

  }

  .goodsListIsNull {
    width: 970px;
    margin: 0 auto;

    .message {
      width: 695px;
      height: 59px;
      margin: 110px auto 100px;
      text-align: center;
      font-size: 22px;
      font-weight: 400;
      color: #2f2f2f;
      line-height: 36px;
    }

    .expertAgent {
      background-color: #fff;
      border: 1px solid #e3e3e3;
      transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
      // margin: 50px 0;
      padding: 40px 150px 55px 150px;
      margin-bottom: 80px;

      svg {
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 auto;
      }

      .expertAgentCon {
        width: 658px;
        padding: 10px;
        margin: 0 auto;

        > * {
          text-align: center;
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        h1 {
          font-size: 21px;
          font-weight: 500;
          line-height: 1.5em;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.8em;
          margin-bottom: 20px;
        }

        button {
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
          padding: 27px 80px 27px 80px;
          border-radius: 4px;
          border: #1a73e8 solid 2px;
          color: #1a73e8;

          &:hover {
            color: #ffffff;
            background-color: #1a73e8;
            border-color: #1a73e8;
          }
        }
      }
    }
  }

  .SearchConterCrosswise {
    width: 1400px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 20px;
    min-height: 500px;
    position: relative;

    .type {
      width: 46px;
      height: 18px;
      display: inline-block;
      background: #FF4000;
      border-radius: 2px;
      margin-right: 7px;
      text-align: center;
      line-height: 18px;
      font-weight: bold;
      color: #fff;
    }

    > .SearchConterCrosswiseCon:nth-child(5n) {
      margin-right: 0 !important;
    }

    > .SearchConterCrosswiseCon {
      margin-right: 18.5px;
      margin-bottom: 15px;
      position: relative;
      box-sizing: border-box;
      border: 2px solid transparent;
      border-radius: 6px;
      height: 430px;

      &:last-child {
        margin-right: 0;
      }

      background-color: white;
      width: 265px;

      > div {
        cursor: pointer;

        &.SearchConterCrosswiseImg {
          width: 261px;
          overflow: hidden;
          height: 261px;
          position: relative;
          transition: 0.3s;

          .inputContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            z-index: 10;
          }

          > .goodsImg {
            background-color: transparent;
          }

          > .el-image {
            width: 100%;
            min-height: 100%;
          }

          .findSimilarityContainer {
            position: absolute;
            left: 0;
            bottom: 38px;
            height: 32px;
            width: 261px;
            background: #E0787E;
            cursor: pointer;
            color: #fff;
            text-align: center;
            line-height: 32px;
            font-size: 12px;
          }

          .findSimilarityContainer:hover {
            background: #C40622;
          }
        }

        &.SearchConterCrosswiseImg:hover {
          > .el-image {
            transform: scale(1.2, 1.2);
          }
        }

        &.rests,
        &.newRests {
          box-sizing: border-box;
          padding: 13px 20px 12px 17px;

          p {
            font-size: 12px;

            font-weight: 400;
            color: #000000;

            &.goodsTitle {
              height: 40px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;

              //p {
              //  width: 46px;
              //  height: 18px;
              //  border-radius: 2px;
              //  background: url("../../assets/1688/goodTag.png") no-repeat;
              //  background-size: 100% 100%;
              //}
            }

            &:nth-child(2) {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              > span {
                line-height: 30px;
                font-size: 12px;
                color: #B4272B;
              }
            }
          }
        }

        &.newRests {
          background: #B4272B;

          p {
            color: #fff;

            &:nth-child(2) {
              > span {
                color: #fff !important;
              }
            }
          }
        }

        .realignmentContainer {
          display: flex;
          flex-wrap: wrap;
          margin: 6px 0;

          div {
            height: 24px;
            background: #FFEFEF;
            border-radius: 4px;
            line-height: 12px;
            text-align: center;
            color: #FF4000;
            font-size: 12px;
            padding: 6px;
            box-sizing: border-box;
          }

          div:first-child {
            margin-right: 6px;
          }
        }

        .repurchaseRate {
          width: 100px;
          height: 24px;
          background: #F6F6F6;
          border-radius: 4px;
          line-height: 24px;
          text-align: center;
          color: #333333;
          font-size: 12px;

          span {
            color: #B4272D;
          }
        }
      }
    }
  }

  .superFactoryContainer, .powerfulMerchantsContainer {
    width: 84px;
    height: 24px;
    background: #FFF5F5;
    border-radius: 4px;
    padding: 0 6px;
    display: flex;
    align-items: center;

    img {
      width: 18px;
      height: 17px;
      margin-right: 5px;
    }

    span {
      color: #F72A2B;
      font-size: 12px;
    }
  }

  .powerfulMerchantsContainer {
    background: #F8F6FF !important;
    width: 131px !important;

    span {
      color: #3700E1 !important;
    }
  }

  .shopInfo {
    padding: 0 15px;
    position: absolute;
    left: 0;
    top: 52%;
    background: #fff;
    box-sizing: border-box;
    width: 261px;
    animation: slideUp 1s ease forwards;

    .shopInfoHeader {
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333333;
      font-size: 14px;

      .goodsFavorite, .goodCompare {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 118px;
        cursor: pointer;
        font-size: 12px;
      }

      .goodCompare {
        border-right: 1px solid #DFDFDF;
      }

      img {
        width: 18px;
        height: 18px;
        margin-right: 7px;
      }
    }

    .shopDataContainer {
      padding: 20px 0 15px;
      border-top: 1px solid #DFDFDF;
      border-bottom: 1px solid #DFDFDF;
      cursor: default;

      .newRateContainer {
        display: flex;
        font-size: 16px;
        color: #999999;
        margin-bottom: 14px;
      }

      .shopDataBottom {
        display: flex;
        align-items: center;
      }
    }

    .shopNameContainer {
      padding: 15px 0;
      display: flex;
      align-items: center;

      .yearContainer, .tpMemberContainer {
        width: 62px;
        height: 24px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #F72A2B;
        display: flex;
        align-items: center;
        margin-right: 5px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        div:first-child {
          width: 26px;
          height: 24px;
          background: #F72A2B;
          border-radius: 4px 0 0 4px;

          img {
            width: 18px;
            height: 17px;
          }
        }

        div:last-child {
          flex: 1;
          font-size: 12px;
          color: #F72A2B;
        }
      }

      .tpMemberContainer {
        border: 1px solid #FF6000 !important;

        div:first-child {
          background: #FF6000 !important;
        }

        div:last-child {
          color: #FF6000 !important;
        }
      }

      .shopName {
        width: 178px;
        font-size: 14px;
        color: #000000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  /* 定义动画 */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .keywordLoadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1400px;

    img {
      width: 40px;
      margin-right: 20px;
    }

    div {
      font-size: 14px;
    }
  }
}

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}
</style>