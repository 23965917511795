<template>
  <div class="dialogContainer">
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$parent.dialogTitle"
        :visible.sync="$parent.setGoodsIntendedForSaleDialogVisible"
        center
        width="910px"
        @close="deleteData">
      <div ref="dialogContent" class="setGoodsIntendedForSaleEditContainer">
        <div class="header">
          <div v-if="JSON.stringify($parent.palletHistoryInfo)=='{}'" style="border-bottom: 1px solid #DFDFDF">
            <div class="fromItemContainer">
              <div class="label">
                <span>*</span>
                <div>{{ $fanyi('名称') }}:</div>
              </div>
              <el-input v-model="$parent.palletConditionEditFrom.name" :placeholder="$fanyi('请输入名称')" size="mini"/>
            </div>
            <div class="fromItemContainer radio-group-flex">
              <div class="label">
                <span>*</span>
                <div> {{ $fanyi('类别') }}:</div>
              </div>
              <el-radio-group v-model="$parent.palletConditionEditFrom.type">
                <el-radio v-if="$parent.status_name==2" :label="1">{{ $fanyi('图片') }}</el-radio>
                <el-radio v-if="$parent.status_name==2" :label="2">{{ $fanyi('关键词') }}</el-radio>
                <el-radio :label="3">
                  <div class="flexAndCenter">
                    <div>{{ $fanyi('链接') }}</div>
                    <el-popover placement="top" trigger="hover" width="358">
                      <div>{{ $fanyi('当前仅支持RAKUMART链接，淘宝链接，1688链接') }}</div>
                      <img slot="reference" alt="" src="../../../assets/tipsIcon.png" style="margin-left: 5px">
                    </el-popover>
                  </div>
                </el-radio>
              </el-radio-group>
            </div>
            <div class="fromItemContainer" style="align-items: normal">
              <div class="label">
                <span>*</span>
                <div>{{ $fanyi('具体信息') }}:</div>
              </div>
              <el-upload
                  v-show="$parent.palletConditionEditFrom.type==1"
                  :action="uploadUrl"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                  :show-file-list="false"
                  class="avatar-uploader">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-input
                  v-show="$parent.palletConditionEditFrom.type==2"
                  v-model="$parent.palletConditionEditFrom.keyword"
                  :rows="5"
                  resize="none"
                  type="textarea">
              </el-input>
              <el-input
                  v-show="$parent.palletConditionEditFrom.type==3"
                  v-model="$parent.palletConditionEditFrom.link"
                  :rows="5"
                  resize="none"
                  type="textarea">
              </el-input>
            </div>
          </div>
          <div v-else class="goodHistoryInfoContainer">
            <el-popover placement="bottom" trigger="hover" width="330">
              <img :src="$parent.palletHistoryInfo.pic" alt="" style="width: 300px;height:300px">
              <img slot="reference" :src="$parent.palletHistoryInfo.pic" alt="" style="width: 100px;height:100px">
            </el-popover>
            <div class="goodHistoryInfoRightContainer">
              <div class="goodsTitle">{{ $parent.palletHistoryInfo.goods_title }}</div>
              <div class="goodHistoryInfoRightFooterContainer">
                <div class="flexAndCenter">
                  <div>{{ $fanyi('历史下单数量') }}</div>
                  <div><span class="colorB4272B">{{ $parent.palletHistoryInfo.confirm_num_total }}</span> 点</div>
                </div>
                <div class="flexAndCenter">
                  <div>{{ $fanyi('历史购买价格') }}</div>
                  <div>最低（<span class="colorB4272B">{{ $parent.palletHistoryInfo.min_confirm_price }}</span>） -
                    最高（<span class="colorB4272B">{{ $parent.palletHistoryInfo.max_confirm_price }}</span>）
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :style="JSON.stringify($parent.palletHistoryInfo)=='{}'?'margin-top:20px':''" class="fromItemContainer"
               style="align-items: normal">
            <div class="label">
              <span>*</span>
              <div>{{ $fanyi('商品查找条件') }}:</div>
            </div>
            <div class="palletCheckedContainer">
              <div class="u-line">{{ $parent.checkedText }}</div>
              <el-button type="primary" @click="indicatorSettingDialogVisible=true;">{{
                  $fanyi('选择条件')
                }}
              </el-button>
            </div>
          </div>
          <div class="fromItemContainer">
            <div class="label">
              <el-popover placement="bottom" trigger="hover" width="358">
                <div>
                  <div>
                    {{ $fanyi('我们会按照您需求的货盘数量，进行查找商品，当数量达到您的要求时，查找会停止。') }}
                  </div>
                  <div style="margin-top: 8px">
                    * {{ $fanyi('数量最多500个，最小1个') }}
                  </div>
                </div>
                <div slot="reference" class="tipsContainer">？</div>
              </el-popover>
              <span>*</span>
              <div>{{ $fanyi('每次获取商品数量') }}:</div>
            </div>
            <el-input v-model="$parent.palletConditionEditFrom.target_count" size="small" style="width: 200px;"/>
          </div>
          <div class="fromItemContainer">
            <div class="label">
              <el-popover placement="bottom" trigger="hover" width="358">
                <div>
                  <div>
                    {{ $fanyi('是否需要每天为您查找商品补充进入货盘') }}
                  </div>
                  <div>
                    {{ $fanyi('设置开通后，每天夜间(12:00)将会自动查找商品') }}
                  </div>
                </div>
                <div slot="reference" class="tipsContainer">？</div>
              </el-popover>
              <span>*</span>
              <div>{{ $fanyi('是否需要每天自动查找商品') }}:</div>
            </div>
            <el-radio-group v-model="$parent.palletConditionEditFrom.is_auto_add">
              <el-radio :label="1" @click.native.prevent="informCustomRadioClickHandler(1,'add')">{{ $fanyi('开启') }}
              </el-radio>
              <el-radio :label="0" @click.native.prevent="informCustomRadioClickHandler(0,'add')">{{ $fanyi('关闭') }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="fromItemContainer">
            <div class="label">
              <el-popover placement="bottom" trigger="hover" width="358">
                <div>
                  <div>
                    {{ $fanyi('商品查找到之后，是否需要人工确认') }}
                  </div>
                  <div>
                    {{ $fanyi('如果设置需要人工确认，商品获取后，先到待确认列表，经由人工操作后，再进入入货盘') }}
                  </div>
                  <div>
                    {{ $fanyi('如果设置不需要人工确认，商品确认后，商品直接加入货盘') }}
                  </div>
                </div>
                <div slot="reference" class="tipsContainer">？</div>
              </el-popover>
              <span>*</span>
              <div>{{ $fanyi('商品是否需要确认') }}:</div>
            </div>
            <el-radio-group v-model="$parent.palletConditionEditFrom.is_auto_confirm">
              <el-radio :label="1" @click.native.prevent="informCustomRadioClickHandler(1,'confirm')">{{
                  $fanyi('开启')
                }}
              </el-radio>
              <el-radio :label="0" @click.native.prevent="informCustomRadioClickHandler(0,'confirm')">{{
                  $fanyi('关闭')
                }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="fromItemContainer">
            <div class="label">
              <el-popover placement="bottom" trigger="hover" width="358">
                <div>
                  {{
                    $fanyi('您可以设置商品查找完成后，是否需要给你进行通知，设置需要通知的情况，需要进行通知时间确认')
                  }}
                </div>
                <div slot="reference" class="tipsContainer">？</div>
              </el-popover>
              <div>{{ $fanyi('商品更新通知') }}:</div>
            </div>
            <el-checkbox-group v-model="radio">
              <el-checkbox :label="1">{{ $fanyi('站内信通知') }}</el-checkbox>
              <el-checkbox :label="2">{{ $fanyi('邮件通知') }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="fromItemContainer">
            <div class="label">
              <div>{{ $fanyi('商品时间') }}:</div>
            </div>
            <el-radio-group v-model="notificationTimeChecked">
              <el-radio :label="1" @click.native.prevent="informCustomRadioClickHandler(1,'checked')">
                {{ $fanyi('实时通知') }}
              </el-radio>
              <el-radio :label="0" @click.native.prevent="informCustomRadioClickHandler(0,'checked')">
                {{ $fanyi('固定时间') }}
                <el-time-select
                    v-model="$parent.palletConditionEditFrom.notice_at"
                    :picker-options="{start: '00:00',step: '00:01',end: '23:59'}"
                    :placeholder="$fanyi('通知时间')"
                    size="small"
                    style="margin-left: 5px">
                </el-time-select>
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="newTipsContainer">
          * {{ $fanyi("我们会按照您设置的通知方式与通知时间给您进行通知") }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
       <el-button @click="$parent.setGoodsIntendedForSaleDialogVisible=false">{{ $fanyi("取消") }}</el-button>
       <el-button type="primary" @click="save">{{ $fanyi("保存") }}</el-button>
    </span>
    </el-dialog>
    <indicatorSetting/>
  </div>
</template>

<script>
import throttle from "@/utlis/throttle";
import indicatorSetting from "@/views/order/components/indicatorSetting.vue";

export default {
  components: {
    indicatorSetting
  },
  data() {
    return {
      customIndicatorDialogVisible: false,
      indicatorSettingDialogVisible: false,
      input: '',
      imageUrl: '',
      indicatorValue: '',
      maxPrice: '',
      minPrice: '',
      remark: '',
      radio: [],
      notificationTimeChecked: 0,
    }
  },
  methods: {
    handleAvatarSuccess(response) {
      this.loading.close();
      if (response.code != 0) {
        this.$message.error(this.$fanyi(response.msg));
        return
      }
      this.imageUrl = response.data;
    },
    beforeAvatarUpload() {
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    informCustomRadioClickHandler(val, type) {
      switch (type) {
        case 'confirm':
          this.$parent.palletConditionEditFrom.is_auto_confirm = this.$parent.palletConditionEditFrom.is_auto_confirm == val ? '' : val;
          break;
        case 'checked':
          this.notificationTimeChecked = this.notificationTimeChecked == val ? '' : val;
          break;
        case 'add':
          this.$parent.palletConditionEditFrom.is_auto_add = this.$parent.palletConditionEditFrom.is_auto_add == val ? '' : val;
          break;
      }
    },
    save() {
      if (this.$parent.palletConditionEditFrom.name == '' || ((this.$parent.palletConditionEditFrom.type == 1 && this.imageUrl == '') || (this.$parent.palletConditionEditFrom.type == 2 && this.$parent.palletConditionEditFrom.keyword == '') || (this.$parent.palletConditionEditFrom.type == 3 && this.$parent.palletConditionEditFrom.link == '') || this.notificationTimeChecked == 0 && (this.$parent.palletConditionEditFrom.notice_at == '' || this.$parent.palletConditionEditFrom.notice_at == null))) {
        this.$message.error(this.$fanyi('缺少必填项'))
        return
      }
      let editFormOption = this.$fun.deepClone(this.$parent.editFormOption);
      editFormOption.forEach((item) => {
        if (item.element_type == 'SINGLE' && item.checkedValue != '') {
          item.option.forEach((optionItem, optionIndex) => {
            if (optionIndex + 1 == item.checkedValue) {
              optionItem.selected = 1
              if (optionItem.name == '自定义价格区间') {
                optionItem.v = [this.minPrice, this.maxPrice]
              }
            } else {
              optionItem.selected = 0
            }
            if (optionIndex == item.option.length - 1) {
              item.checkedValue = undefined;
            }
          })
        }
        if (item.element_type != 'SINGLE') {
          item.option.forEach((optionItem, optionIndex) => {
            if (item.checkedValue.indexOf(optionIndex + 1) != -1) {
              optionItem.selected = 1
            } else {
              optionItem.selected = 0
            }
            if (optionIndex == item.option.length - 1) {
              item.checkedValue = undefined;
            }
          })
        }
      })
      let datas = {
        id: this.$parent.dialogTitle == this.$fanyi('添加') ? undefined : this.$parent.palletConditionEditFrom.id,
        name: this.$parent.palletConditionEditFrom.name,
        condition: JSON.stringify(editFormOption),
        pallet_type: this.$parent.status_name,
        type: this.$parent.palletConditionEditFrom.type,
        image_url: this.$parent.palletConditionEditFrom.type == 1 ? this.imageUrl : '',
        keyword: this.$parent.palletConditionEditFrom.type == 2 ? this.$parent.palletConditionEditFrom.keyword : '',
        link: this.$parent.palletConditionEditFrom.type == 3 ? this.$parent.palletConditionEditFrom.link : '',
        notice_system: this.radio.indexOf(1) != -1 ? 1 : 0,
        notice_email: this.radio.indexOf(2) != -1 ? 1 : 0,
        notice_at: this.$parent.palletConditionEditFrom.notice_at,
        target_count: this.$parent.palletConditionEditFrom.target_count,
        template_id: this.$parent.conditionId != null ? this.$parent.conditionId : undefined,
        is_auto_add: this.$parent.palletConditionEditFrom.is_auto_add,
        is_auto_confirm: this.$parent.palletConditionEditFrom.is_auto_confirm,
      }
      if (this.$parent.dialogTitle == this.$fanyi('添加')) {
        throttle(() => {
          this.$api.storePalletCondition(datas).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.$message.success(this.$fanyi(res.msg))
            this.$parent.setGoodsIntendedForSaleDialogVisible = false;
            this.$parent.getPalletConditionList();
          })
        }, 3000)
      } else {
        this.$api.updatePalletCondition(datas).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg))
          this.$parent.setGoodsIntendedForSaleDialogVisible = false;
          this.$parent.getPalletConditionList();
        })
      }
    },
    deleteData() {
      this.input = '';
      this.imageUrl = '';
      this.radio = [];
      this.maxPrice = '';
      this.minPrice = '';
      this.indicatorValue = '';
      this.remark = '';
      this.$parent.palletHistoryInfo = {};
    }
  }
}
</script>

<style lang="scss" scoped>
.dialogContainer {
  /deep/ .el-dialog {
    border-radius: 10px;
    box-sizing: border-box;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      padding-bottom: 7px;
      border-bottom: 4px solid #B4272B;
    }

    .el-dialog__headerbtn {
      font-size: 19px;

      .el-dialog__close {
        color: #B4272b;
      }
    }
  }

  .tipsContainer {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #B4272B;
    color: #B4272B;
    cursor: pointer;
    line-height: 14px;
    font-size: 12px;
    padding-left: 3.5px;
    margin-right: 6px;
  }

  .requiredText {
    font-size: 14px;
    color: #B4272B;
  }

  .setGoodsIntendedForSaleEditContainer {
    padding: 40px 30px 0;

    .header {
      .goodHistoryInfoContainer {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #DFDFDF;

        .goodHistoryInfoRightContainer {
          margin-left: 10px;

          .goodsTitle {
            font-size: 14px;
            color: #333333;
            height: 15px;
            margin-bottom: 34px;
          }

          .goodHistoryInfoRightFooterContainer {

            .flexAndCenter:first-child {
              margin-bottom: 14px;
            }

            .flexAndCenter {
              div:first-child {
                font-size: 14px;
                color: #999999;
                margin-right: 24px;
              }
            }
          }
        }
      }

      .fromItemContainer {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .label {
          width: 212px;
          min-width: 212px;
          font-size: 14px;
          color: #333333;
          text-align: right;
          margin-right: 24px;
          display: flex;
          justify-content: flex-end;

          span {
            color: #B4272B;
            font-size: 14px;
            margin-right: 6px;
          }
        }

        .palletCheckedContainer {
          div {
            height: 17px;
            font-size: 14px;
            width: 610px;
            color: #333333;
            margin-bottom: 10px;
          }
        }

        /deep/ .el-radio-group {
          margin-top: 3px;
        }

        /deep/ .el-checkbox__original {
          display: none;
        }

        .avatar-uploader {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          box-sizing: border-box;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409EFF;
        }

        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 117px;
          height: 117px;
          line-height: 117px;
          text-align: center;
        }

        .avatar {
          width: 117px;
          height: 117px;
          display: block;
        }
      }

      .radio-group-flex {
        /deep/ .el-radio-group, /deep/ .el-radio-group .el-radio {
          display: flex;
        }
      }
    }

    .newTipsContainer {
      color: #B4272B;
      font-size: 14px;
      margin: 30px 0 28px;
      text-align: center;
    }

    .footer {
      padding-top: 20px;

      .title {
        font-size: 18px;
        color: #333333;
        margin-bottom: 10px;
      }

      .indicatorListContainer {
        background: #F6F6F6;
        border-radius: 10px;
        padding: 0 18px;

        .indicatorContainer {
          padding-top: 18px;
          border-bottom: 1px solid #DFDFDF;

          .indicatorName {
            margin-bottom: 15px;
            font-size: 16px;
            color: #333333;
          }

          .el-radio, .el-checkbox {
            margin-bottom: 12px;
          }

          /deep/ .el-radio-group {
            display: flex;
            flex-wrap: wrap;
          }

          .el-radio {
            display: flex;
            align-items: center;
          }

          /deep/ .el-checkbox__input {
            input[type="checkbox"] {
              width: 0;
              height: 0;
            }

            line-height: 0.1px;
          }
        }

        .indicatorContainer:last-child {
          border-bottom: none;
        }
      }

      .flex {
        margin-top: 17px;
        padding-bottom: 10px;

        div {
          font-size: 14px;
          color: #B4272B;
          text-decoration-line: underline;
          margin-right: 5px;
        }

        img {
          width: 14px;
          height: 14px;
          margin-top: 4px;
        }
      }
    }

    .tableContainer {
      .tableLabel {
        font-size: 18px;
        color: #333333;
        margin: 20px 0 14px 0;
      }

      .table {
        .tableHeader {
          display: flex;
          align-items: center;
          height: 46px;
          border-radius: 10px 10px 0 0;
          background: #F0F0F0;

          li {
            line-height: 46px;
            flex: 1;
            padding-left: 30px;
          }
        }

        .tableFooter {
          overflow-y: scroll;
          max-height: 150px;
          width: 100%;

          ul {
            height: 50px;
            border-bottom: 1px solid #DFDFDF;
            display: flex;
            align-items: center;
            width: 100%;

            li {
              line-height: 50px;
              flex: 1;
              padding-left: 30px;
            }
          }
        }

        .tableFooter::-webkit-scrollbar {
          width: 0;
        }
      }
    }
  }

  .scrollContainer {
    height: 600px;
    overflow-y: scroll;
  }

  .el-dialog__footer {

    button {
      border-radius: 8px;
      height: 34px;
      width: 120px;
      line-height: 34px;
      padding: 0;
      font-size: 14px;
    }

    button:first-child {
      color: #B4272B;
      border: 1px solid #B4272B;
      margin-right: 26px;
    }

    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>