<template>
  <div>
    <HomePageTop/>
    <div class="pageHeaderContainer">
      <div class="header">
        {{ $fanyi("自组货源") }}
      </div>
      <div class="footer">
        <div>
          <div class="tabsContainer flex">
            <el-tabs v-model="status_name" type="card" @tab-click="tabHandleClick">
              <el-tab-pane :label="$fanyi('在售货源优化')" name="1"></el-tab-pane>
              <el-tab-pane :label="$fanyi('意向售卖参考')" name="2"></el-tab-pane>
              <el-tab-pane :label="$fanyi('榜单商品推荐')" name="3"></el-tab-pane>
            </el-tabs>
            <div class="tabsRightContainer"></div>
            <el-popover v-show="status_name==2" trigger="hover" width="200">
              <div>{{ $fanyi('搜索条件设置完成之后，每天24点自动帮您寻找符合您条件的商品，商品一天更新一次') }}</div>
              <img slot="reference" alt="" class="intentionToSellReferenceTips cursorPointer"
                   src="../../assets/tipsIcon.png">
            </el-popover>
          </div>
        </div>
        <div v-show="status_name==2" class="intentionToSellReferenceContainer">
          <div class="intentionToSellReferenceHeaderContainer">
            <div v-for="(item,index) in goodsIntendedForSaleList" :key="index"
                 :style="item.checked?'border: 1px solid #B4272B;':''"
                 class="goodsIntendedForSaleItem"
                 @click="item.checked=!item.checked;goodsChange()"
            >
              <div class="checkedContainer">
                <input v-model="item.checked" style="cursor: pointer" type="checkbox" @change.stop="goodsChange"/>
              </div>
              <div class="goodsInfoHeader">
                <img v-if="item.type==1" :src="item.image_url" alt="">
                <div>
                  <el-popover v-if="item.type!=1" trigger="hover" width="400">
                    <div>{{ item.type == 2 ? item.keyword : item.link }}</div>
                    <span slot="reference"
                          class="u-line-2 fontSize14" style="min-height: 42px">{{
                        item.type == 2 ? item.keyword : item.link
                      }}</span>
                  </el-popover>
                  <div :style="item.type!=1?'margin-top: 0':'margin-top:41px'" class="flexAndCenter">
                    <div class="btn" @click.stop="setGoodsIntendedForSale(item)">{{ $fanyi('设置') }}</div>
                    <div class="btn" style="margin-left: 8px;background:#2C2D43;"
                         @click.stop="deletePallet(item)">{{ $fanyi('删除') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="palletDetailContainer">
                <div class="flexAndCenter">
                  <div class="label">{{ $fanyi('商品更新时间') }}</div>
                  <div class="fontSize14 color333">{{ item.updated_at }}</div>
                </div>
                <div class="flexAndCenter" style="margin: 10px 0">
                  <div class="label">{{ $fanyi('货盘内商品') }}</div>
                  <div class="fontSize14 color333">{{ item.user_pallet_product_count }}</div>
                </div>
                <div class="flexAndCenter" style="margin-bottom: 10px">
                  <div class="label">{{ $fanyi('商品每日自动补充') }}</div>
                  <div class="fontSize14 color333">{{ item.is_auto_add == 1 ? $fanyi('开启') : $fanyi('关闭') }}</div>
                </div>
                <div class="flexAndCenter" style="margin-bottom: 10px">
                  <div class="label">{{ $fanyi('通知') }}</div>
                  <div class="fontSize14 color333">{{
                      item.notice_email == 0 ? '' : item.notice_system == 1 ? '①邮件；②站内信' : '①邮件'
                    }}{{ item.notice_system == 1 && item.notice_email == 0 ? '②站内信' : '' }}
                  </div>
                </div>
                <div :style="item.checkedText!=''?'margin-bottom: 14px':'margin-bottom: 113px'" class="flexAndCenter">
                  <div class="label">{{ $fanyi('通知时间') }}</div>
                  <div class="fontSize14 color333">{{
                      item.notice_at != null ? item.notice_at : $fanyi('实时通知')
                    }}
                  </div>
                </div>
                <div v-if="item.checkedText!=''" class="palletCheckedTextContainer">
                  <div :title="item.name" class="palletCheckedTextTitle u-line cursorPointer">{{ item.name }}</div>
                  <el-popover trigger="hover" width="300">
                    <div>{{ item.checkedText }}</div>
                    <div slot="reference" class="fontSize14 color333 cursorPointer u-line2" style="line-height: 20px">
                      {{ item.checkedText }}
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="palletFooterContainer">
                <div class="palletFooterLeftContainer">
                  <div v-if="item.run_start_at==null&&item.user_pallet_product_wait_count==0" class="leftTextContainer">
                    {{ $fanyi('目前没有商品查找任务，可以点击“立即查找”为您补充商品') }}
                  </div>
                  <div v-else class="leftTextContainer">
                    已从 {{ item.run_from_count }} 商品中<br>
                    为您匹配到<span>&nbsp;{{ item.run_complete_count }}</span> 个商品<br>
                    <span>&nbsp;{{ item.user_pallet_product_wait_count }}&nbsp;</span>个商品待确认
                  </div>
                  <div class="btnList">
                    <div v-if="item.run_start_at==null" class="btn"
                         @click.stop="immediatelySelectGoods(item)">{{ $fanyi('立即查找') }}
                    </div>
                    <div v-if="item.user_pallet_product_wait_count>0" class="btn" style="margin: 0 8px 0 12px;"
                         @click.stop="pageJump(item)">
                      {{ $fanyi('商品确认') }}
                    </div>
                    <div :style="item.user_pallet_product_wait_count>0?'':'margin-left:8px'">？</div>
                  </div>
                </div>
                <div v-show="item.loadingStatus" class="palletFooterRightContainer">
                  <div class="loading-spinner">
                    <div>{{ item.loadingProgress }}%</div>
                  </div>
                  <div class="loadingProgressText">
                    {{ item.loadingProgress == 100 ? $fanyi("查找完成") : $fanyi("查找中") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btnList">
            <div @click="addIntentionToSellReference">{{ $fanyi("添加意向售卖商品") }}</div>
          </div>
        </div>
        <listProductRecommendation v-show="status_name==3" ref="listProductRecommendationRef"/>
      </div>
    </div>
    <historicalPurchaseDataReference v-show="status_name==1"/>
    <intentionToSellReference
        v-show="status_name==2||(status_name==1&&goodsIntendedForSaleList.length>0&&showStatus==false)"
        ref="componentRef"/>
    <setGoodsIntendedForSale ref="setGoodsIntendedForSaleRef"/>
    <Foot/>
  </div>
</template>

<script>
import intentionToSellReference from './components/intentionToSellReference.vue'
import HomePageTop from "../../components/head/HomePageTop.vue";
import listProductRecommendation from "@/views/order/components/listProductRecommendation.vue";
import setGoodsIntendedForSale from "@/views/order/components/setGoodsIntendedForSale.vue";
import historicalPurchaseDataReference from "@/views/order/components/historicalPurchaseDataReference.vue";
import Foot from "../../components/foot/Foot.vue";

export default {
  components: {
    HomePageTop,
    listProductRecommendation,
    intentionToSellReference,
    setGoodsIntendedForSale,
    historicalPurchaseDataReference,
    Foot
  },
  data() {
    return {
      status_name: '1',
      palletConditionFrom: {
        user_pallet_id: '',
        sortPrice: '',
        sortMonthSold: '',
        sortRepurchaseRate: '',
        page: 1,
        pageSize: 50
      },
      palletConditionEditFrom: {},
      listProductRecommendationFrom: {
        keywords: '',
        productCollectionId: '205451985',
        page: 1,
        pageSize: 50
      },
      clientRecentBuyTopFrom: {
        page: 1,
        pageSize: 10
      },
      showStatus: false,
      typeList: [],
      timer: null,
      conditionId: null,
      goodsList: [],
      formOption: [],
      editFormOption: [],
      palletSuggestList: [],
      clientHistoryTopList: [],
      clientRecentBuyTopList: [],
      setGoodsIntendedForSaleDialogVisible: false,
      palletHistoryInfo: {},
      goodsIntendedForSaleList: [],
      checkedText: '',
      palletConditionInfo: {},
      hotSaleCatesList: [],
      defaultGoodsList: [],
      withDefaultGoodsList: [],
    }
  },
  created() {
    this.getPalletConditionList();
    this.getPalletSuggestList();
    this.getClientHistoryTopList();
    this.getClientRecentBuyTopList();
    //获取首页爆品推荐分类
    this.$api.getHotSaleCates().then((res) => {
      if (res.code != 0) return this.$message.error(res.msg);
      res.data.forEach((item) => {
        item.childs.forEach((items) => {
          items.checked = false;
        })
      })
      this.hotSaleCatesList = res.data;
    });
    this.getListWithDefaultGoods();
    ///获取目标货源的表单选项
    this.$api.getFormOption().then((res) => {
      res.data.forEach((item) => {
        if (item.element_type == 'SINGLE') {   //单选
          item.checkedValue = ''
        } else {
          item.checkedValue = []
        }
      })
      this.formOption = res.data;
    })
  },
  mounted() {
    window.addEventListener("scroll", this.getbottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getbottom);
  },
  methods: {
    //获取榜单列表的默认一级分类商品
    getListWithDefaultGoods() {
      this.$api.listWithDefaultGoods().then((res) => {
        res.data.forEach((item, itemIndex) => {
          this.typeList.push({
            product_collection_id: item.product_collection_id,
            name_ja: item.name_ja
          })
          item.default_goods_list.forEach((items) => {
            items.goods_list = items.goods_list[0];
            items.goods_list.forEach((goodsItem) => {
              goodsItem.hoverStatus = false;
              if (goodsItem.whiteImage != undefined && goodsItem.whiteImage != '') {
                goodsItem.imgUrl = goodsItem.whiteImage
              }
              if (goodsItem.tradeScore != undefined) {
                goodsItem.tradeScore = Number(goodsItem.tradeScore)
              }
              if (goodsItem.shopInfo != undefined && !this.$fun.isArray(goodsItem.shopInfo) && goodsItem.shopInfo.shopTime != undefined) {
                if (goodsItem.shopInfo.shopTime.length != 19) {
                  goodsItem.shopInfo.year = 0
                } else {
                  let year = goodsItem.shopInfo.shopTime.substr(0, 4);
                  var date = new Date();
                  goodsItem.shopInfo.year = Number(date.getFullYear()) - Number(year);
                }
              }
            })
            if (itemIndex == 0) {
              this.listProductRecommendationFrom.productCollectionId = item.product_collection_id;
              this.defaultGoodsList.push(items)
            }
          })
        })
        this.withDefaultGoodsList = res.data;
        this.newFavoriteGoodsCheckIn();
      });
    },
    //商品确认页面跳转
    pageJump(item) {
      window.open('/goodsToBeConfirmedOnThePallet?user_pallet_id=' + item.id);
    },
    //tab切换事件
    tabHandleClick(tab) {
      this.goodsIntendedForSaleList = [];
      if (tab.name == '1' || tab.name == '2') {
        this.getPalletConditionList();
      }
      this.listProductRecommendationFrom.page = 1;
      this.palletConditionFrom.page = 1;
      this.goodsList = [];
      document.documentElement.scrollTop = 0;
      if (this.timer != null) {
        window.clearInterval(this.timer);
      }
    },
    //获取货盘列表
    getPalletConditionList() {
      this.$api.getPalletConditionList({
        page: 1,
        pallet_type: this.status_name,
        pageSize: 1000
      }).then((res) => {
        res.data.data.forEach((item, index) => {
          item.checked = index == 0 ? true : false;
          item.checkedText = '';
          item.loadingProgress = 0;
          item.loadingStatus = false;
          if (item.run_start_at != null) {
            item.loadingStatus = true;
            this.getJobProcess(item)
          }
          item.condition.forEach((conditionItem, conditionIndex) => {
            conditionItem.option.forEach((optionItem, optionIndex) => {
              if (optionItem.selected == 1) {
                item.checkedText += optionItem.name_trans + '；'
              }
              if (conditionIndex == item.condition.length - 1 && optionIndex == conditionItem.option.length - 1 && item.checkedText != '') {
                item.checkedText = item.checkedText.substr(0, item.checkedText.length - 1);
              }
            })
          })
        })
        if (res.data.data.length != 0) {
          this.palletConditionFrom.user_pallet_id = res.data.data[0].id;
          this.getGoodsList();
        }
        if (res.data.data.length == 0 && this.status_name == 1) {
          this.showStatus = true;
        }
        this.goodsIntendedForSaleList = res.data.data;
      });
    },
    //立即查找商品
    immediatelySelectGoods(item) {
      this.$api.startRun({user_pallet_id: item.id}).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg))
        this.getPalletConditionList();
      })
    },
    //获取货盘抓取货盘商品进度
    getJobProcess(val) {
      if (val.run_start_at != null) {
        this.timer = setInterval(() => {
          this.$api.getJobProcess({
            user_pallet_id: val.id,
          }).then((res) => {
            if (res.data.notCompleteCountCal == res.data.total) {
              val.loadingProgress = 100;
              this.goodsList = [];
              this.palletConditionFrom.user_pallet_id = val.id;
              this.goodsIntendedForSaleList.forEach((item) => {
                item.checked = item == val;
              })
              val.loadingStatus = false;
              this.getPalletConditionList();
              this.getGoodsList();
              window.clearInterval(this.timer);
            } else {
              let finishedGoodCount = Number(res.data.total) - Number(res.data.notCompleteCountCal);
              val.loadingProgress = (100 * (finishedGoodCount / Number(res.data.total))).toFixed(0);
            }
          })
        }, 2000);
      }
    },
    //获取自己的自定义指标列表
    getPalletSuggestList() {
      this.$api.getPalletSuggestList({
        page: 1,
        pageSize: 1000
      }).then((res) => {
        this.palletSuggestList = res.data.data
      })
    },
    //获取客户历史购买top9数据
    getClientHistoryTopList() {
      this.$api.clientHistoryTop().then((res) => {
        this.clientHistoryTopList = res.data.data;
      })
    },
    //获取客户历史购买top9数据
    getClientRecentBuyTopList() {
      this.$api.clientRecentBuyTop(this.clientRecentBuyTopFrom).then((res) => {
        if (res.data.data.length < 10) {
          this.$refs.componentRef.goodsListIsNull = true
        }
        this.$refs.componentRef.keywordLoading = false
        res.data.data.forEach((item) => {
          this.clientRecentBuyTopList.push(item)
        })
      })
    },
    //查看某商品是否被收藏
    newFavoriteGoodsCheckIn() {
      let ids = [];
      this.defaultGoodsList.forEach((item) => {
        item.goods_list.forEach((goodsItem) => {
          goodsItem.goodsFavoriteStatus = false;
          ids.push(goodsItem.goodsId);
        })
      });
      this.$api.favoriteGoodsCheckIn({
        goods_id: ids,
        shop_type: 1688
      })
      .then((res) => {
        if (res.code != 0) return false;
        res.data.forEach((resItem) => {
          this.defaultGoodsList.forEach((item) => {
            item.goods_list.forEach((goodsItem) => {
              if (resItem.goods_id == goodsItem.goodsId) {
                goodsItem.goodsFavoriteStatus = resItem.inFavorite
              }
            })
          })
        });
      });
    },
    //货盘勾选事件
    goodsChange() {
      this.goodsList = [];
      this.palletConditionFrom.user_pallet_id = '';
      this.palletConditionFrom.page = 1;
      this.goodsIntendedForSaleList.forEach((item, index) => {
        if (item.checked) {
          this.palletConditionFrom.user_pallet_id += item.id + ','
        }
        if (index == this.goodsIntendedForSaleList.length - 1 && this.palletConditionFrom.user_pallet_id != '') {
          this.palletConditionFrom.user_pallet_id = this.palletConditionFrom.user_pallet_id.substr(0, this.palletConditionFrom.user_pallet_id.length - 1);
          this.$refs.componentRef.goodsListIsNull = false;
          this.getGoodsList();
        }
      })
    },
    //货盘商品搜索
    getGoodsList() {
      this.$refs.componentRef.loading = true;
      this.$api.palletProductList(this.palletConditionFrom).then((res) => {
        this.$refs.componentRef.loading = false;
        this.$refs.componentRef.keywordLoading = false;
        if (res.code != 0) {
          this.$refs.componentRef.goodsListIsNull = true;
          this.$message.error(this.$fanyi(res.data))
          return
        }
        if ((Number(res.data.total) < 50)) {
          this.$refs.componentRef.keywordLoading = undefined;
        }
        if (res.data.data.length != 0) {
          res.data.data.forEach((item) => {
            item.checked = false;
            item.hoverStatus = false;
            if (item.goods_detail.tradeScore != undefined) {
              item.goods_detail.tradeScore = Number(item.goods_detail.tradeScore)
            }
            if (item.shopInfo != undefined && !this.$fun.isArray(item.shopInfo) && item.shopInfo.shopTime != undefined) {
              if (item.shopInfo.shopTime.length != 19) {
                item.shopInfo.year = 0
              } else {
                let year = item.shopInfo.shopTime.substr(0, 4);
                var date = new Date();
                item.shopInfo.year = Number(date.getFullYear()) - Number(year);
              }
            }
            this.goodsList.push(item)
          })
          this.favoriteGoodsCheckIn();
          this.goodsListIsNull = false;
        } else {
          this.$refs.componentRef.keywordLoading = undefined;
          this.$refs.componentRef.goodsListIsNull = true;
        }
      });
    },
    //添加货盘
    addIntentionToSellReference() {
      this.palletConditionEditFrom = {
        name: '',
        client_remark: '',
        type: 1,
        image_url: '',
        keyword: '',
        link: '',
        notice_system: 0,
        notice_at: '',
        target_count: '100',
        template_id: '',
        is_auto_add: '1',
        is_auto_confirm: '1'
      };
      this.editFormOption = this.$fun.deepClone(this.formOption);
      this.dialogTitle = this.$fanyi('添加');
      this.setGoodsIntendedForSaleDialogVisible = true;
    },
    //货盘编辑
    setGoodsIntendedForSale(item) {
      let arr = this.$fun.deepClone(item.condition)
      this.checkedText = '';
      arr.forEach((arrItem) => {
        if (arrItem.element_type == 'SINGLE') {   //单选
          arrItem.checkedValue = ''
          for (let i = 0; i < arrItem.option.length; i++) {
            if (arrItem.option[i].selected == 1) {
              arrItem.checkedValue = i + 1;
              this.checkedText += arrItem.option[i].name_trans + '；';
              if (arrItem.option[i].name == '自定义价格区间') {
                this.minPrice = arrItem.option[i].v[0];
                this.maxPrice = arrItem.option[i].v[1];
              }
              break;
            }
          }
        } else {
          arrItem.checkedValue = []
          arrItem.option.forEach((optionItem, optionIndex) => {
            if (optionItem.selected == 1) {
              this.checkedText += optionItem.name_trans + '；';
              arrItem.checkedValue.push(optionIndex + 1)
            }
          })
        }
      })
      this.editFormOption = arr;
      this.checkedText = this.checkedText.substr(0, this.checkedText.length - 1);
      this.$refs.setGoodsIntendedForSaleRef.imageUrl = item.image_url;
      if (item.notice_email == 1) {
        this.$refs.setGoodsIntendedForSaleRef.radio.push(2)
      }
      if (item.notice_system == 1) {
        this.$refs.setGoodsIntendedForSaleRef.radio.push(1)
      }
      if (item.notice_at != null) {
        this.$refs.setGoodsIntendedForSaleRef.notificationTimeChecked = 0
      } else {
        this.$refs.setGoodsIntendedForSaleRef.notificationTimeChecked = 1;
      }
      if (item.history != undefined) {
        this.palletHistoryInfo = item.history;
      }
      this.palletConditionEditFrom = {
        id: item.id,
        name: item.name,
        client_remark: item.client_remark,
        pallet_type: this.status_name,
        type: item.type,
        image_url: item.image_url,
        keyword: item.keyword,
        link: item.link,
        notice_system: 1,
        notice_at: item.notice_at,
        target_count: item.target_count,
        template_id: '',
        is_auto_add: item.is_auto_add,
        is_auto_confirm: item.is_auto_confirm
      };
      this.dialogTitle = this.$fanyi('编辑');
      this.setGoodsIntendedForSaleDialogVisible = true;
    },
    //删除货盘
    deletePallet(item) {
      this.$confirm(
          this.$fanyi('确认删除该货盘吗？'),
          '注意',
          {
            confirmButtonText: this.$fanyi('确定'), //确定
            cancelButtonText: this.$fanyi('取消'), //取消
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        this.$api.deletePalletCondition({
          id: item.id,
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg))
          this.getPalletConditionList();
        })
      }).catch(() => {

      });
    },
    //在售货源优化展开收起事件
    switchoverShowStatus() {
      this.showStatus = !this.showStatus;
      this.clientRecentBuyTopFrom.page = 0;
      this.palletConditionFrom.page = 1;
      this.clientRecentBuyTopList = [];
      this.goodsList = [];
      this.$refs.componentRef.keywordLoading = false;
      this.$refs.componentRef.goodsListIsNull = false;
    },
    // 查看商品是否已收藏
    favoriteGoodsCheckIn() {
      let ids = [];
      this.goodsList.forEach((goodsItem) => {
        goodsItem.goodsFavoriteStatus = false;
        ids.push(goodsItem.goods_id);
      });
      this.$api.favoriteGoodsCheckIn({
        goods_id: ids,
        shop_type: 1688
      })
      .then((res) => {
        if (res.code != 0) return false;
        res.data.forEach((resItem, resIndex) => {
          this.goodsList[resIndex].goodsFavoriteStatus = resItem.inFavorite;
        });
      });
    },
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      let numHeight = scrollTop + clientHeight;
      if (numHeight > scrollHeight - 600 && ((this.$refs.componentRef.keywordLoading === false && this.$refs.componentRef.goodsListIsNull == false) || (this.$refs.listProductRecommendationRef.keywordLoading === false && this.$refs.listProductRecommendationRef.goodsListIsNull == false))) {
        if (this.$refs.componentRef.keywordLoading === false && this.$refs.componentRef.goodsListIsNull == false) {
          this.$refs.componentRef.keywordLoading = true
        }
        if (this.$refs.listProductRecommendationRef.keywordLoading === false && this.$refs.listProductRecommendationRef.goodsListIsNull == false) {
          this.$refs.listProductRecommendationRef.keywordLoading = true
        }
        this.fn()
      }
    },
    fn() {
      if (this.$refs.componentRef.keywordLoading === true && (this.status_name == 1 && this.showStatus == false || this.status_name == 2)) {
        this.palletConditionFrom.page++;
        this.getGoodsList();
      }
      if (this.$refs.componentRef.keywordLoading === true && this.status_name == 1 && this.showStatus == true) {
        this.clientRecentBuyTopFrom.page++;
        this.getClientRecentBuyTopList();
      }
      if (this.$refs.listProductRecommendationRef.keywordLoading === true && this.status_name == 3) {
        this.listProductRecommendationFrom.page++;
        this.$refs.listProductRecommendationRef.getGoodsList();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.pageHeaderContainer {
  width: 1400px;
  margin: 24px auto 30px;
  background: #FFFFFF;
  border-radius: 6px;
  overflow: hidden;

  .header {
    height: 70px;
    padding-left: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 70px;
    border-bottom: 1px solid #DFDFDF;
  }

  .footer {
    padding: 30px;

    .tabsContainer {
      position: relative;
      overflow: hidden;

      .tabsRightContainer {
        flex: 1;
        height: 40px;
        border-bottom: 1px solid #B4272B;
        width: 100%;
      }

      /deep/ .el-tabs__header {
        border-bottom: none;
      }

      /deep/ .is-active {
        border-top: 1px solid #B4272B !important;
        border-right: 1px solid #B4272B !important;
        border-left: 1px solid #B4272B !important;
        border-bottom: 1px solid transparent !important;
      }

      /deep/ .el-tabs__item {
        border-radius: 10px 10px 0 0;
        border-top: 1px solid #DFDFDF;
        border-left: 1px solid #DFDFDF;
        border-right: 1px solid #DFDFDF;
        border-bottom: 1px solid #B4272B;
        padding: 0 15px;
      }

      /deep/ .el-tabs__nav {
        border: none;
      }

      .intentionToSellReferenceTips {
        position: absolute;
        top: 5px;
        right: 0;
      }
    }

    .intentionToSellReferenceContainer {
      .intentionToSellReferenceHeaderContainer {
        display: flex;
        flex-wrap: wrap;

        .goodsIntendedForSaleItem {
          width: 436px;
          border-radius: 4px;
          margin: 0 16px 18px 0;
          padding: 8px 8px 15px 15px;
          border: 1px solid #DFDFDF;
          cursor: pointer;

          .checkedContainer {
            display: flex;
            justify-content: flex-end;
          }

          .goodsInfoHeader {
            display: flex;

            img {
              width: 100px;
              height: 100px;
              margin-right: 10px;
            }

            .btn {
              width: 110px;
              height: 32px;
              background: #B4272B;
              border-radius: 4px;
              cursor: pointer;
              line-height: 32px;
              text-align: center;
              font-size: 12px;
              color: #FEFEFE;
              margin-top: 26px;
            }
          }

          .palletDetailContainer {
            border-top: 1px dashed #999999;
            margin-right: 7px;
            padding-top: 10px;
            margin-top: 20px;

            .palletCheckedTextContainer {
              width: 406px;
              height: 87px;
              background: #FAF2F2;
              border-radius: 4px;
              padding: 10px 10px 12px 12px;
              margin-bottom: 12px;

              .palletCheckedTextTitle {
                font-weight: bold;
                font-size: 14px;
                color: #B4272B;
                margin-bottom: 16px;
              }
            }
          }

          .palletFooterContainer {
            border-top: 1px dashed #DFDFDF;
            padding: 10px 45px 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .palletFooterLeftContainer {
              width: 184px;

              .leftTextContainer {
                font-size: 14px;
                color: #333333;
                line-height: 24px;
                margin-bottom: 17px;

                span {
                  color: #B4272B;
                }
              }

              .btnList {
                display: flex;
                align-items: center;

                .btn {
                  width: 72px;
                  height: 32px;
                  background: #B4272B;
                  border-radius: 4px;
                  text-align: center;
                  cursor: pointer;
                  line-height: 32px;
                  font-size: 12px;
                  color: #FEFEFE;
                }

                div:last-child {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  border: 1px solid #B4272B;
                  background: transparent !important;
                  color: #B4272B;
                  cursor: pointer;
                  line-height: 18px;
                  padding-left: 5.1px;
                  font-size: 14px;
                }
              }
            }

            .palletFooterRightContainer {
              width: 66px;
              display: flex;
              flex-direction: column;
              align-items: center;

              .loading-spinner {
                width: 66px;
                height: 66px;
                border-radius: 50%;
                border: 4px solid #f3f3f3;
                border-top: 4px solid #3498db;
                animation: spin 1s linear infinite;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #333333;

                div {
                  animation: spin 1s linear infinite;
                  animation-direction: reverse;
                }
              }

              .loadingProgressText {
                font-size: 14px;
                color: #333333;
                margin-top: 10px;
              }

              @keyframes spin {
                0% {
                  transform: rotateZ(0deg);
                }
                100% {
                  transform: rotateZ(360deg);
                }
              }
            }
          }

          .label {
            width: 126px;
            margin-right: 10px;
            font-size: 14px;
            color: #999999;
          }
        }

        .goodsIntendedForSaleItem:last-child {
          margin-right: 0;
        }
      }

      input[type="checkbox"]:checked {
        background: #B4272B;
        border: none;
      }

      .btnList {
        display: flex;
        align-items: center;

        div {
          height: 36px;
          background: #50C136;
          border-radius: 4px;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 36px;
          padding: 0 10px;
          margin-right: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>