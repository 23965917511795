<template>
  <div>
    <div class="classifyContainer">
      <div class="fontWeightBold fontSize18 cursorPointer" style="margin-bottom: 20px" @click="resetSelect">{{
          $fanyi('所有分类')
        }}
      </div>
      <div class="scrollContainer">
        <el-collapse v-model="activeNames" @change="collapseChange">
          <el-collapse-item v-for="(item,index) in $parent.hotSaleCatesList" :key="index" :name="index"
                            :title="item.translate_name">
            <div style="padding-left: 12px">
              <div v-for="(childItem,childIndex) in item.childs" :key="childIndex"
                   :style="activeIndex==childItem.translate_name?'color:#B4272B;':''"
                   class="classifyChildren"
                   @click="searchClassifyGoods(childItem)">
                {{ childItem.translate_name }}
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="typeListContainer">
      <div v-for="(item,index) in $parent.typeList" :key="index"
           :class="$parent.listProductRecommendationFrom.productCollectionId==item.product_collection_id?'colorB4272B':''"
           @click="updateProductCollectionId(item)">
        {{ item.name_ja }}
      </div>
    </div>
    <div v-loading="loading">
      <div v-for="(item,index) in $parent.defaultGoodsList" :key="index">
        <div class="cateTranslateNameContainer">{{ item.cate_translate_name }}</div>
        <div class="SearchConterCrosswise">
          <div v-for="(goodsItem, goodsIndex) in item.goods_list" :key="goodsIndex"
               :class="activeIndex==null?'SearchConterCrosswiseCon':'SearchConterCrosswiseCon margin-bottom-15'"
               :data-aplus-report="goodsItem.traceInfo"
               :style="goodsItem.hoverStatus === true ? 'border: 1px solid #B4272B;' : ''"
               data-tracker="offer"
               @mouseleave="goodsMouseleave(index,goodsIndex)">
            <div v-if="activeIndex==null">
              <div v-if="goodsIndex==1" class="TOP1">TOP{{ goodsIndex + 1 }}</div>
              <div v-else-if="goodsIndex==2" class="TOP2">TOP{{ goodsIndex + 1 }}</div>
              <div v-else-if="goodsIndex==3" class="TOP3">TOP{{ goodsIndex + 1 }}</div>
              <div v-else class="TOP4AndTOP5">TOP{{ goodsIndex + 1 }}</div>
            </div>
            <!-- 放图片的div -->
            <div class="SearchConterCrosswiseImg" @click="toCommodityDetails(goodsItem)"
                 @mouseenter="goodsMouseenter(index,goodsIndex)">
              <el-image :src="goodsItem.imgUrl" class="biggoodsImg">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <div slot="placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
              <!--              <div v-show="goodsItem.hoverStatus === true" class="findSimilarityContainer"-->
              <!--                   @click.stop="imgSearchGoods(goodsItem.imgUrl)">{{ $fanyi("查找相似") }}-->
              <!--              </div>-->
            </div>
            <!-- 显示其他信息的div -->
            <div v-show="goodsItem.hoverStatus === false"
                 class="rests">
              <p :title="goodsItem.titleT" class="goodsTitle">
                <span v-if="goodsItem.shopType==1688" class="type">1688</span>
                {{ goodsItem.titleT }}
              </p>
              <p>
              <span>
                {{ Number(goodsItem.goodsPrice).toFixed(2) }}{{ $fanyi("元") }}
                （{{ (Number(goodsItem.goodsPrice) * exchangeRate).toFixed(0) }}{{ $fanyi("円") }}）
              </span>
                <span v-if="goodsItem.shopType==1688" style="color: #999999">販売数<span
                    style="color: #B4272D!important;">{{
                    goodsItem.monthSold
                  }}</span>件</span>
              </p>
              <div style="display: flex;flex-wrap: wrap">
                <div v-if="goodsItem.shopType==1688" style="display: flex;flex-wrap: wrap;margin-bottom: 6px">
                  <div v-for="(newItem,newIndex) in goodsItem.sellerIdentities" :key="newIndex">
                    <div v-if="newItem=='super_factory'" :style="newIndex==0?'margin-right: 4px':''"
                         class="powerfulMerchantsContainer">
                      <img alt="" src="../../../assets/1688/powerfulMerchantsIcon.png">
                      <span>スーパーメーカー</span>
                    </div>
                    <div v-if="newItem=='powerful_merchants'" :style="newIndex==0?'margin-right: 4px':''"
                         class="superFactoryContainer">
                      <img alt="" src="../../../assets/1688/superFactoryIcon.png">
                      <span>実力商家</span>
                    </div>
                  </div>
                </div>
                <div v-if="goodsItem.shopType==1688" class="repurchaseRate">リピート率
                  <span>{{ Number(goodsItem.repurchaseRate) }}</span>％
                </div>
              </div>
            </div>
            <div v-show="goodsItem.hoverStatus === true"
                 class="shopInfo">
              <div class="shopInfoHeader" style="justify-content: space-between">
                <div class="goodCompare" @click.stop="addCommercialMatch(goodsItem)">
                  <img alt="" src="../../../assets/1688/goodCompare.png">
                  比較
                </div>
                <div class="goodsFavorite" @click.stop="favoriteGoodsAdd(goodsItem)">
                  <img v-if="goodsItem.goodsFavoriteStatus" alt=""
                       src="../../../assets/SearchResultsPage/Collected.png">
                  <img v-else alt="" src="../../../assets/SearchResultsPage/collection.png">
                  {{ goodsItem.goodsFavoriteStatus ? $fanyi("取消收藏") : $fanyi("收藏") }}
                </div>
              </div>
              <div v-if="goodsItem.shopType==1688"
                   :style="goodsItem.shopInfo==undefined||$fun.isArray(goodsItem.shopInfo)||(!$fun.isArray(goodsItem.shopInfo)&&goodsItem.shopInfo.shopName==undefined&&goodsItem.shopInfo.year==undefined)?'border-bottom:none':''"
                   class="shopDataContainer">
                <div class="newRateContainer">
                  <div style="margin-right: 7px">総合評点</div>
                  <el-rate
                      v-model="goodsItem.tradeScore"
                      disabled
                      text-color="#ff9900">
                  </el-rate>
                </div>
                <div class="shopDataBottom">
                  <div class="repurchaseRate" style="margin-right:6px">リピート率
                    <span>{{ Number(goodsItem.repurchaseRate) }}</span>％
                  </div>
                  <div class="repurchaseRate" style="width: 128px;">販売数<span>{{
                      goodsItem.monthSold
                    }}</span>件
                  </div>
                </div>
              </div>
              <div
                  v-if="goodsItem.shopInfo!=undefined&&!$fun.isArray(goodsItem.shopInfo)&&goodsItem.shopType==1688&&goodsItem.shopInfo.shopName!=undefined&&goodsItem.shopInfo.year!=undefined"
                  class="shopNameContainer">
                <div
                    v-if="goodsItem.sellerIdentities!=undefined&&goodsItem.sellerIdentities.indexOf('tp_member')==-1"
                    class="yearContainer">
                  <div><img alt="" src="../../../assets/1688/shopIcon.png"></div>
                  <div>{{ item.shopInfo.year }}年</div>
                </div>
                <div
                    v-if="goodsItem.sellerIdentities!=undefined&&goodsItem.sellerIdentities.indexOf('tp_member')!=-1"
                    class="tpMemberContainer">
                  <div><img alt="" src="../../../assets/1688/tp_member_icon.png"></div>
                  <div>{{ goodsItem.shopInfo.year }}年</div>
                </div>
                <div :title="goodsItem.shopInfo.shopName" class="shopName cursorPointer"
                     @click="shopPageJump(goodsItem)">{{
                    goodsItem.shopInfo.shopName
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="keywordLoading" class="keywordLoadingContainer">
        <div class="flexAndCenter">
          <img alt="" src="@/assets/loading.gif">
          <div>読み込み中</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: [],
      activeIndex: null,
      keywordLoading: false,
      loading: false,
      goodsListIsNull: false,
      collapseChangeIndex: 0,
    }
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  methods: {
    goodsMouseleave(index, index1) {
      this.$parent.defaultGoodsList[index].goods_list[index1].hoverStatus = false;
      this.$forceUpdate();
    },
    resetSelect() {
      this.$parent.defaultGoodsList = [];
      this.$parent.withDefaultGoodsList.forEach((item, index) => {
        if (index == 0) {
          item.default_goods_list.forEach((items) => {
            this.$parent.defaultGoodsList.push(items)
          })
        }
      })
    },
    collapseChange(event) {
      if (event.length != 0) {
        this.collapseChangeIndex = this.$fun.deepClone(event[event.length - 1])
      }
      this.searchClassifyGoods(this.$parent.hotSaleCatesList[this.collapseChangeIndex].childs[0])
    },
    searchClassifyGoods(val) {
      if (val.chinese_name != this.$parent.listProductRecommendationFrom.keywords) {
        this.activeIndex = val.translate_name;
        this.$parent.listProductRecommendationFrom.keywords = val.chinese_name;
        this.$parent.defaultGoodsList = [{
          cate_translate_name: val.translate_name,
          goods_list: [],
        }];
        this.getGoodsList();
      }
    },
    updateProductCollectionId(item) {
      this.$parent.listProductRecommendationFrom.productCollectionId = item.product_collection_id;
      if (this.activeIndex != null) {
        this.$parent.defaultGoodsList[0].goods_list = [];
        this.getGoodsList();
      } else {
        this.$parent.defaultGoodsList = [];
        this.$parent.withDefaultGoodsList.forEach((newItem) => {
          if (newItem.product_collection_id == item.product_collection_id) {
            newItem.default_goods_list.forEach((items) => {
              this.$parent.defaultGoodsList.push(items)
            })
          }
        })
      }
    },
    toCommodityDetails(val) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('通过意向售卖参考的商品展示进入商品详情页统计');
      }
      this.$fun.toCommodityDetails(val.goodsId, val.shopType)
    },
    goodsMouseenter(index, index1) {
      this.$parent.defaultGoodsList[index].goods_list[index1].hoverStatus = true;
      this.$forceUpdate();
    },
    imgSearchGoods(img) {
      this.$store.commit("getsearchImg", img);
      this.$fun.routerToPage("/CommoditySearch?type=imgSearch&imgUrl=" + img);
    },
    addCommercialMatch(val) {
      let commercialMatchList = []
      if (localStorage.getItem("commercialMatchList") != null) {
        commercialMatchList = JSON.parse(localStorage.getItem('commercialMatchList'));
      }
      if (commercialMatchList.length < 30) {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('榜单商品推荐增加比较商品数量统计');
        }
        let flag = commercialMatchList.findIndex(item => item.title == val.titleT)
        if (flag != -1) {
          this.$message.error('該当商品は追加済みです、他の商品をお選び下さい。')
        } else {
          commercialMatchList.push({
            imgUrl: val.imgUrl,
            title: val.titleT,
            titleC: val.titleC,
            price: val.goodsPrice,
            shopType: val.shopType,
            goodsId: val.goodsId,
            monthSold: val.monthSold == undefined ? '' : Number(val.monthSold),
            repurchaseRate: val.repurchaseRate == undefined ? '' : Number(val.repurchaseRate),
            shopName: val.shopInfo.shopName,
            link: `https://www.rakumart.com/ProductDetails?goods_id=${val.goodsId}&fromPlatform=${val.shopType}`
          })
          localStorage.setItem("commercialMatchList", JSON.stringify(commercialMatchList));
          this.$store.commit('setCommercialMatchGoodsCount', commercialMatchList.length)
          this.$message.success('操作が成功しました')
        }
      } else {
        this.$message.error('最大30個の商品を追加できます')
      }
    },
    favoriteGoodsAdd(item) {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      let datas = {
        shop_type: item.shopType,
        goods_id: item.goodsId,
        title: item.titleC,
        image_url: item.imgUrl,
        price: item.goodsPrice,
      };
      if (!item.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$message.success(this.$fanyi(res.msg))
          this.$forceUpdate();
        });
      } else {
        let arr = [];
        arr.push({
          shop_type: item.shopType,
          goods_id: item.goodsId
        })
        this.$api.favoriteGoodsDelete({delete: arr}).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg))
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$forceUpdate();
        });
      }
    },
    shopPageJump(item) {
      let super_factory_status = undefined;
      let powerful_merchants_status = undefined;
      if (item.sellerIdentities != undefined) {
        item.sellerIdentities.indexOf('super_factory') != -1 ? super_factory_status = true : undefined
        item.sellerIdentities.indexOf('powerful_merchants') != -1 ? powerful_merchants_status = true : undefined
      }
      if ((item.shopInfo.shopName != '' && item.shopInfo.shopName != undefined) && ((item.shopInfo.shop_id != '' && item.shopInfo.shop_id != undefined) || (item.shopInfo.shopId != '' && item.shopInfo.shopId != undefined)) && (item.shopInfo.wangwang != '' && item.shopInfo.wangwang != undefined)) {
        this.$fun.routerToPage(`/shopGoods?shopId=${item.shopInfo.shop_id == undefined ? item.shopInfo.shopId : item.shopInfo.shop_id}&wangName=${item.shopInfo.wangwang}&shopName=${item.shopInfo.shopName}&shopType=1688${super_factory_status == true ? '&super=true' : ''}${powerful_merchants_status == true ? '&powerful=true' : ''}`)
      }
    },
    getGoodsList() {
      if (this.$parent.defaultGoodsList[0].goods_list.length === 0) {
        this.loading = true;
      }
      if (this.loading === false) {
        this.keywordLoading = true;
      }
      this.$api.EuropeKeywordSearchProduct(this.$parent.listProductRecommendationFrom).then((res) => {
        this.loading = false;
        this.keywordLoading = false;
        if (res.code != 0) {
          this.goodsListIsNull = true;
          this.$message.error(this.$fanyi(res.data))
          return
        }
        if ((Number(res.data.total) < 50)) {
          this.keywordLoading = undefined;
        }
        if (res.data.result.result.length != 0) {
          res.data.result.result.forEach((item) => {
            item.hoverStatus = false;
            if (item.tradeScore != undefined) {
              item.tradeScore = Number(item.tradeScore)
            }
            if (item.shopInfo != undefined && !this.$fun.isArray(item.shopInfo) && item.shopInfo.shopTime != undefined) {
              if (item.shopInfo.shopTime.length != 19) {
                item.shopInfo.year = 0
              } else {
                let year = item.shopInfo.shopTime.substr(0, 4);
                var date = new Date();
                item.shopInfo.year = Number(date.getFullYear()) - Number(year);
              }
            }
            this.$parent.defaultGoodsList[0].goods_list.push(item)
          })
          this.$parent.newFavoriteGoodsCheckIn();
          this.goodsListIsNull = false;
        } else {
          this.keywordLoading = undefined;
          this.goodsListIsNull = true;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.classifyContainer {
  position: fixed;
  top: 240px;
  left: 0;
  width: 200px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 12px 12px 0;
  padding: 20px;
  background: #fff;

  /deep/ .el-collapse, /deep/ .el-collapse-item__header, /deep/ .el-collapse-item__wrap {
    border: none;
  }

  /deep/ .el-collapse-item__content {
    padding-bottom: 0;
  }

  /deep/ .el-collapse-item__header {
    height: 17px;
    line-height: 17px;
    margin-bottom: 22px;
  }

  .classifyChildren {
    width: 149px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .scrollContainer {
    height: 400px;
    overflow-y: scroll
  }

  .scrollContainer::-webkit-scrollbar {
    width: 0;
  }
}

.typeListContainer {
  height: 70px;
  background: #F0F0F0;
  border-radius: 4px;
  padding-left: 30px;
  display: flex;
  align-items: center;

  div {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-right: 60px;
    height: 70px;
    line-height: 70px;
    cursor: pointer;
  }

  .colorB4272B {
    border-bottom: 5px solid #B4272B;
  }
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.cateTranslateNameContainer {
  height: 66px;
  line-height: 66px;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}

.SearchConterCrosswise {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .TOP1, .TOP2, .TOP3, .TOP4AndTOP5 {
    width: 50px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 24px;
    text-align: center;
  }

  .TOP1 {
    background: url("../../../assets/selfOrganizingSourceGoods/TOP1.png") no-repeat;
    background-size: 100% 100%;
  }

  .TOP2 {
    background: url("../../../assets/selfOrganizingSourceGoods/TOP2.png") no-repeat;
    background-size: 100% 100%;
  }

  .TOP3 {
    background: url("../../../assets/selfOrganizingSourceGoods/TOP3.png") no-repeat;
    background-size: 100% 100%;
  }

  .TOP4AndTOP5 {
    background: url("../../../assets/selfOrganizingSourceGoods/TOP4AndTOP5.png") no-repeat;
    background-size: 100% 100%;
  }

  .type {
    width: 46px;
    height: 18px;
    display: inline-block;
    background: #FF4000;
    border-radius: 2px;
    margin-right: 7px;
    text-align: center;
    line-height: 18px;
    font-weight: bold;
    color: #fff;
  }

  > .SearchConterCrosswiseCon:nth-child(5n) {
    margin-right: 0 !important;
  }

  > .SearchConterCrosswiseCon {
    margin-right: 12px;
    position: relative;
    box-sizing: border-box;
    border: 1px solid #DFDFDF;
    border-radius: 6px;
    height: 430px;

    &:last-child {
      margin-right: 0;
    }

    background-color: white;
    width: 258px;

    > div {
      cursor: pointer;

      &.SearchConterCrosswiseImg {
        margin: 12px;
        width: 234px;
        overflow: hidden;
        height: 234px;
        position: relative;
        transition: 0.3s;

        > .goodsImg {
          background-color: transparent;
        }

        > .el-image {
          width: 100%;
          min-height: 100%;
        }

        .findSimilarityContainer {
          position: absolute;
          left: 0;
          bottom: 23px;
          height: 32px;
          width: 234px;
          background: #E0787E;
          cursor: pointer;
          color: #fff;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
        }

        .findSimilarityContainer:hover {
          background: #C40622;
        }
      }

      &.rests,
      &.newRests {
        box-sizing: border-box;
        padding: 13px 20px 12px 17px;

        p {
          font-size: 12px;

          font-weight: 400;
          color: #000000;

          &.goodsTitle {
            height: 40px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;

            //p {
            //  width: 46px;
            //  height: 18px;
            //  border-radius: 2px;
            //  background: url("../../assets/1688/goodTag.png") no-repeat;
            //  background-size: 100% 100%;
            //}
          }

          &:nth-child(2) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            > span {
              line-height: 30px;
              font-size: 12px;
              color: #B4272B;
            }
          }
        }
      }

      &.newRests {
        background: #B4272B;

        p {
          color: #fff;

          &:nth-child(2) {
            > span {
              color: #fff !important;
            }
          }
        }
      }

      .realignmentContainer {
        display: flex;
        flex-wrap: wrap;
        margin: 6px 0;

        div {
          height: 24px;
          background: #FFEFEF;
          border-radius: 4px;
          line-height: 12px;
          text-align: center;
          color: #FF4000;
          font-size: 12px;
          padding: 6px;
          box-sizing: border-box;
        }

        div:first-child {
          margin-right: 6px;
        }
      }

      .repurchaseRate {
        width: 100px;
        height: 24px;
        background: #F6F6F6;
        border-radius: 4px;
        line-height: 24px;
        text-align: center;
        color: #333333;
        font-size: 12px;

        span {
          color: #B4272D;
        }
      }
    }
  }
}

.superFactoryContainer, .powerfulMerchantsContainer {
  width: 84px;
  height: 24px;
  background: #FFF5F5;
  border-radius: 4px;
  padding: 0 6px;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 17px;
    margin-right: 5px;
  }

  span {
    color: #F72A2B;
    font-size: 12px;
  }
}

.powerfulMerchantsContainer {
  background: #F8F6FF !important;
  width: 131px !important;

  span {
    color: #3700E1 !important;
  }
}

.shopInfo {
  padding: 0 15px;
  position: absolute;
  left: 0;
  top: 52%;
  background: #fff;
  box-sizing: border-box;
  width: 248px;
  animation: slideUp 1s ease forwards;

  .shopInfoHeader {
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 14px;

    .goodsFavorite, .goodCompare {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 118px;
      cursor: pointer;
      font-size: 11px;
    }

    .goodCompare {
      border-right: 1px solid #DFDFDF;
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }
  }

  .shopDataContainer {
    padding: 20px 0 15px;
    border-top: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    cursor: default;

    .newRateContainer {
      display: flex;
      font-size: 16px;
      color: #999999;
      margin-bottom: 14px;
    }

    .shopDataBottom {
      display: flex;
      align-items: center;
    }
  }

  .shopNameContainer {
    padding: 15px 0;
    display: flex;
    align-items: center;

    .yearContainer, .tpMemberContainer {
      width: 62px;
      height: 24px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #F72A2B;
      display: flex;
      align-items: center;
      margin-right: 5px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:first-child {
        width: 26px;
        height: 24px;
        background: #F72A2B;
        border-radius: 4px 0 0 4px;

        img {
          width: 18px;
          height: 17px;
        }
      }

      div:last-child {
        flex: 1;
        font-size: 12px;
        color: #F72A2B;
      }
    }

    .tpMemberContainer {
      border: 1px solid #FF6000 !important;

      div:first-child {
        background: #FF6000 !important;
      }

      div:last-child {
        color: #FF6000 !important;
      }
    }

    .shopName {
      width: 178px;
      font-size: 14px;
      color: #000000;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

/* 定义动画 */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.keywordLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1400px;

  img {
    width: 40px;
    margin-right: 20px;
  }

  div {
    font-size: 14px;
  }
}
</style>